<template>
  <div id="landingpage">
    <Header :logo="'hide'" back-title="Shopping Cart" />
    <c-box paddingTop="30px" paddingBottom="80px">
      <c-box w="100%" maxWidth="1200px" mx="auto">
        <c-heading
          as="h2"
          fontSize="36px"
          fontWeight="600"
          color="black.900"
          marginBottom="40px"
          textAlign="center"
          lineHeight="54px"
        >
          Submit Berhasil!
        </c-heading>

        <c-flex marginTop="100px" justifyContent="center">
          <c-box w="100%" :max-w="['173px', '641px']" textAlign="center">
            <c-image
              :src="require('@/assets/paid.png')"
              mx="auto"
              marginBottom="30px"
            />
            <c-text
              marginBottom="20px"
              color="gray.900"
              :fontSize="['14px', '24px']"
              :lineHeight="['21px', '36px']"
              fontFamily="Roboto"
            >
              Progress &amp; Tracker kamu berhasil di-submit
            </c-text>

            <c-button
              as="router-link"
              :to="{ name: 'client.progress-tracker' }"
              fontWeight="700"
              right-icon="arrow-forward"
              color="brand.900"
              variant="outline"
              borderColor="brand.900"
              borderRadius="200px"
              py="10px"
              px="30px"
            >
              Progress Tracker
            </c-button>
          </c-box>
        </c-flex>
      </c-box>
    </c-box>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/header/index.vue"
import Footer from "@/components/Footer.vue"
import { mapGetters } from "vuex"

export default {
  name: "SuccessSubmitProgressPage",
  components: {
    Header,
    Footer,
  },
}
</script>

<style></style>
